import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKalina as author } from 'data/authors';
import { useSourceParams } from 'hooks/useSourceParams';
import img from 'img/blog/covers/sessionRecordings.png';

import img1 from 'img/blog/lsAppDashboard.png';
import img2 from 'img/blog/lsAppFilters.png';
import img3 from 'img/marketplace/covers/defaultCoverIntegrations.png';

const Content = () => {
  const bannerLink = useSourceParams('banner');
  const bannerCenter = useSourceParams('banner-center');
  const btn = useSourceParams('button-end');
  return (
    <Post>
      <p>Looking for SaaS optimization solutions that actually bring results?</p>
      <p>
        <strong>Session recording tools</strong> bring a lot to the table. They provide an excellent
        opportunity to:
      </p>
      <ul>
        <li>
          <strong>Add more context to the user journey </strong>– Session replays allow you to watch
          how visitors interacted with the landing page and what caused them to move forward in the
          sales funnel. You can also use session recordings to find potential onboarding issues.
        </li>

        <li>
          <strong>See how the users interact with your web application</strong> – You can track
          every scroll, every mouse movement, and every click, just like you’re sitting next to each
          other.
        </li>

        <li>
          <strong>Find bugs and usability issues </strong>– Many of these are detected
          automatically. We’re going to describe that below in more detail!
        </li>
      </ul>
      <p>
        We know that session replays may seem overwhelming at first. You’ve got plenty of{' '}
        <a href="/blog/guide-to-qualitative-ux-research/" target="_blank" rel="noopener noreferrer">
          qualitative data
        </a>{' '}
        to process. If you were to watch all the recordings, one after the other, you’d probably
        need some extra hours in the day.
      </p>
      <p>Luckily, analyzing session recordings can be more effective than that!</p>
      <p>
        In this article, we’re going to show you how to use session recordings to grow your SaaS
        business. We’ll go through some of the tools, tips, and tricks you’re sure to find useful.
        Last but not least, we’re also featuring a real-life <strong>SaaS success story!</strong>
      </p>
      <p>Ready to get the most out of your SaaS with the help of session replays? Let’s dive in!</p>

      <a className="action-banner" href={bannerLink.link} target="_blank" rel="noopener noreferrer">
        Try a new session recording tool and see what&apos;s in it for your Saas! Test LiveSession
        for free.
      </a>

      <ul>
        <li>
          <a href="#find-bugs-quicker">Find bugs quicker</a>
          <ul>
            <li>
              <a href="#qualitative-insights">Qualitative insights</a>
            </li>
            <li>
              <a href="#automatic-detection-rage-clicks-and-error-clicks">
                Automatic detection – rage clicks and error clicks
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#use-filters-and-custom-segments">Use filters and custom segments</a>
          <ul>
            <li>
              <a href="#filters">Filters</a>
            </li>
            <li>
              <a href="#custom-segments-for-saas-analytics">Custom segments for SaaS analytics</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#integrate-with-other-saas-tools">Integrate with other SaaS tools</a>
        </li>
        <li>
          <a href="#saas-and-session-recordings-success-story-rocketlink">
            SaaS and session recordings success story: RocketLink
          </a>
        </li>
      </ul>

      <h2 id="find-bugs-quicker">Find bugs quicker</h2>

      <p>
        We’ve already mentioned this advantage in the introduction. There’s a couple of different
        ways session replays can help you find bugs on your website and in your SaaS product:
      </p>
      <h3 id="qualitative-insights">Qualitative insights</h3>

      <p>
        As soon as you start watching session recordings, you’ll soon realize they’re a goldmine of
        information. Even when we watch recordings of our website, we’re often surprised by user
        behavior we couldn’t predict.
      </p>
      <p>
        Session replays can help you find the mismatch between your{' '}
        <a
          href="https://www.webdesignerdepot.com/2020/06/how-to-benefit-from-mental-models-in-ux-design/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          mental models
        </a>{' '}
        and the mental models of your users. You and the users might have very different ideas about
        how things work. For example, we often find out that visitors consider some features to be
        clickable while they’re not.
      </p>
      <h3 id="automatic-detection-rage-clicks-and-error-clicks">
        Automatic detection – rage clicks and error clicks
      </h3>
      <img src={img1} alt="LiveSession App" title="Dashboard" />
      <p>
        Aside from observations only, LiveSession detects two types of potential issues
        automatically. They’re called{' '}
        <a href="/blog/error-rage-clicks/" target="_blank" rel="noopener noreferrer">
          rage clicks and error clicks
        </a>
        :
      </p>
      <ul>
        <li>
          <strong>Rage clicks</strong> are registered when the user clicks on something repeatedly
          and very fast. This is an indicator that they got frustrated with a feature that’s not
          working properly.
        </li>

        <li>
          <strong>Error clicks</strong>, on the other hand, apply to technical issues, specifically
          JavaScript errors. The user might not realize that something is wrong in the app, yet this
          kind of error suggests that there’s probably something to look into.
        </li>
      </ul>
      <p>
        Thanks to dedicated <strong>filters</strong>, these errors are really easy to browse. See
        how it works:
      </p>
      <h2>Use session recording filters and custom segments</h2>

      <p>
        You can apply a wide variety of <strong>session recording filters </strong>to narrow down
        your search results and find the most interesting session recordings faster.
      </p>
      <p>
        Different combinations of filters can be saved as <strong>custom segments</strong> to
        analyze later. You can also share these segments with your teammates and discuss your
        findings with them.
      </p>
      <h3 id="filters">Filters</h3>

      <img src={img2} alt="Filters" title="LiveSesssion filters list" />

      <p>
        LiveSession offers an extensive choice of session recording filters. You can filter
        recordings based on location, duration, system properties, and much, much more! If you’d
        like to dig deeper, we recommend our{' '}
        <a
          href="/blog/how-to-use-filters-in-user-research/"
          target="_blank"
          rel="noopener noreferrer"
        >
          article about user segmentation
        </a>
        .
      </p>
      <p>
        In this post, we’re going to focus on the ones that can be particularly useful for SaaS
        businesses:
      </p>
      <ul>
        <li>
          <strong>Pages count </strong>– You can choose to see recordings where the users have
          visited more than just one page. This will give you more insights into the user journey.
        </li>

        <li>
          <strong>First session </strong>– It’s a great way to analyze the behavior of users who are
          visiting your website for the first time.{' '}
        </li>

        <li>
          <strong>Start URL, Exit URL, Visited URL </strong>– This group of filters will help you
          analyze sessions where users have interacted with a specific page and see how it affected
          their experience.
        </li>

        <li>
          <strong>Referrer, UTM tag</strong> – With these filters, you can get in-depth insights on
          the results of your campaigns. Use them to see how the interactions vary depending on
          where the users came from, e.g. social media shares or paid campaigns.
        </li>

        <li>
          <strong>Device, mobile type, browser, OS, screen size </strong>– These filters will help
          you analyze how the experience varies depending on the technical variables. Some pages or
          features may work well on the desktop yet leave a lot to be desired on mobile.
        </li>

        <li>
          <strong>Name, email, custom property</strong> – Last but not least, you can add custom
          data to identify the users. These features allow you to match the recording with the
          user’s name, email, or other types of data. One of the most common custom properties is
          the <strong>pricing plan</strong>. This filter is also used for integrations with other
          SaaS apps.
        </li>
      </ul>

      <a
        className="action-banner"
        href={bannerCenter.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Test the filters yourself! Try LiveSession for free, no credit card required.
      </a>

      <p>
        Now that you know the most important filters, it’s time to move on to the next step:
        combining these into <strong>custom segments</strong>.
      </p>
      <h3 id="custom-segments-for-saas-analytics">Custom segments for SaaS analytics</h3>

      <p>
        Need inspiration for your SaaS optimization strategy? Here are some of the custom segments
        we’re using ourselves:
      </p>
      <ul>
        <li>
          <strong>Segments for PPC campaigns</strong> – Analyze the user journeys and the behavior
          of visitors who came from Facebook, Google Ads, or other PPC marketing campaigns.
        </li>

        <li>
          <strong>User who visited page X – </strong>e.g., users who visited the Pricing, or the
          Features page. These key sections are usually the biggest SaaS conversion rate
          optimization challenges. You can see how they interacted with this information, how they
          got to this page and what were their next steps.
        </li>

        <li>
          <strong>A/B test segments </strong>– It’s a fast and easy way to gain more qualitative
          insight into your A/B tests! We enjoy this strategy so much we’ve created our own guide to
          A/B testing with session recordings. Check it out if you’d like to learn even more about
          growing SaaS businesses!
        </li>

        <li>
          <strong>Feature-focused segments</strong> – When you introduce a new feature, you can set
          up a dedicated segment for it. It’s an easy way to actually see how people interact with
          it! You can see even the slightest moments of doubt.
        </li>
      </ul>
      <p>
        Now that we’ve mentioned A/B testing, it’s high time we moved on to the next step:
        LiveSession <strong>integrations</strong>.
      </p>
      <h2 id="integrate-with-other-saas-tools">Integrate with other SaaS tools</h2>
      <img
        src={img3}
        alt="Connect your favorite tools to LiveSession"
        title="Integrate tools with LiveSession"
      />
      <p>
        LiveSession offers a wide array of integrations with other SaaS tools. You can browse all of
        them in our{' '}
        <a href="/integrations/" target="_blank" rel="noopener noreferrer">
          integrations marketplace
        </a>{' '}
        – and we’re constantly adding new ones, too!
      </p>
      <p>Here are some of the highlights when it comes to SaaS optimization and growth:</p>
      <ul>
        <li>
          <strong>Google Optimize </strong>– You can easily set up an A/B test with the Google
          Optimize tool and analyze session recordings for different variants. Test variants appear
          as custom filters in LiveSession.
        </li>

        <li>
          <strong>Google Analytics, Heap, Segment, and Mixpanel</strong> – LiveSession recordings
          can appear as events in Google Analytics, Heap, Mixpanel and Segment. This way, you can
          analyze different kinds of data in one place.
        </li>

        <li>
          <strong>Sentry, Bugsnag, and Errorception </strong>– To top it all off, you can integrate
          LiveSession with the most popular bug tracking tools. This makes the workflow of your
          software developers much smoother! This way, it’s much easier to research the bug and find
          an optimal solution.{' '}
        </li>

        <li>
          <strong>Intercom, LiveChat, and other customer support tools </strong>– As you’re already
          aware, satisfying customer support is crucial for retention and, ultimately, growth. Your
          customer support agents can access LiveSession recordings straight from the conversation
          in LiveChat or Intercom. This way, they can provide relevant help faster.
        </li>
      </ul>
      <h2 id="saas-and-session-recordings-success-story-rocketlink">
        SaaS and session recordings success story: RocketLink
      </h2>

      <p>
        Working with session recordings can result in visible, quantifiable growth. You don’t have
        to take our word for it – have a look at these numbers and results from RocketLink:
      </p>
      <ul>
        <li>
          Among other things, fixing discount code issues with LiveSession helped the company
          achieve a <strong>110% increase in signups. </strong>Session recordings helped RocketLink
          find issues they wouldn’t spot otherwise.{' '}
        </li>

        <li>
          <strong>Reduced churn</strong> thanks to improved onboarding and discovered issues. These
          included users pasting an invalid format of the pixel ID and other bugs that couldn’t be
          reproduced otherwise. What’s more, they wouldn’t even discover many of the issues if not
          for LiveSession.{' '}
        </li>

        <li>
          Last but not least, RocketLink claims they’ve reached{' '}
          <strong>40% more paid subscriptions </strong>thanks to improvements they’ve implemented
          with LiveSession.
        </li>
      </ul>
      <p>
        Sounds promising? Read more in our{' '}
        <a href="/customers/rocketlink/" target="_blank" rel="noopener noreferrer">
          RocketLink case study
        </a>{' '}
        and see how a SaaS company can succeed with the help of the right session recording tool.
      </p>
      <p>
        We hope this article has been a source of inspiration for you! Now that you know how to grow
        your SaaS business with session recordings, why not try them yourself?
      </p>
      <a target="_blank" rel="noopener noreferrer" href={btn.link} className="action-btn">
        Sign up free
      </a>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'How to use Session Recordings to Grow Your SaaS',
  url: '/blog/how-to-use-session-recordings-to-grow-your-saas/',
  description: `Looking for an effective optimization platform for your SaaS? Read our guide to session recordings. `,
  author,
  img,
  imgSocial: img,
  date: '2020-07-29',
  category: '',
  group: 'Analytics',
  timeToRead: 7,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
